import './AnimatedPlayBars.css'

// todo: tried to lift from repo but didn't work first time so said fuck it
//https://samuelkraft.com/blog/animated-music-bars
//https://github.com/samuelkraft/samuelkraft-next/blob/master/components/nowplaying.module.scss

const NowPlaying = () => {
	return (
		<div class="nowplaying_icon__2aDf-"><span></span><span></span><span></span></div>
	)
}
export default NowPlaying
